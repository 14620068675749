import React, { useEffect } from "react";

function ImageLoader({ champions, tisers, setChampionImages, setTierImages }) {
  useEffect(() => {
    const loadImages = async () => {
      const images = {};
      const tiers = {};

      // 티어 이미지와 챔피언 이미지를 동시에 로드
      const loadTierImages = Array.from({ length: 5 }, async (_, i) => {
        const tier = i + 1;
        try {
          const response = await fetch(`/image/asset/tier_${tier}.svg`);
          const blob = await response.blob();
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              tiers[tier] = reader.result;
              resolve();
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
        } catch (error) {
          console.error(`Failed to load tier image ${tier}:`, error);
        }
      });

      const loadChampionImages = champions.map(async (champion) => {
        if (champion && champion.id) {
          try {
            const response = await fetch(`/image/champion/${champion.id}.png`);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                images[champion.id] = reader.result;
                resolve();
              };
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            });
          } catch (error) {
            console.error(
              `Failed to load image for champion ${champion.id}:`,
              error
            );
          }
        }
      });

      // 모든 이미지를 병렬로 로드
      await Promise.all([...loadTierImages, ...loadChampionImages]);

      setChampionImages(images);
      setTierImages(tiers);
    };

    if (champions && champions.length > 0) {
      loadImages();
    }
  }, [champions, tisers, setChampionImages, setTierImages]);

  return null;
}

export default ImageLoader;
